import {
    ADD_PERMISSION_REQUEST,
    ADD_PERMISSION_SUCCESS,
    ADD_PERMISSION_FAIL,
    GET_PERMISSION_FAIL,
    GET_PERMISSION_SUCCESS,
    GET_PERMISSION_REQUEST,
    UPDATE_PERMISSION_REQUEST,
    UPDATE_PERMISSION_SUCCESS,
    UPDATE_PERMISSION_FAIL
     } from "./../constant/permissionConstants"
     
     export const permissionReducer = (state = { permission: {} }, { type, payload }) => {
         switch (type) {
     
             case ADD_PERMISSION_REQUEST: return {
                 ...state,
                 loading: true
             }
             case  ADD_PERMISSION_SUCCESS: return {
                 ...state,
                 addedPermission: payload,
                 addPermission:true,
                 loading: false
             }
     
             case ADD_PERMISSION_FAIL: return {
                 ...state,
                 error: payload,
                 loading: false
             }
    
             case GET_PERMISSION_REQUEST: return {
                ...state,
                loading: true
            }
            case  GET_PERMISSION_SUCCESS: return {
                ...state,
                permission: payload,
                loading: false
            }
    
            case GET_PERMISSION_FAIL: return {
                ...state,
                error: payload,
                loading: false
            }

            case UPDATE_PERMISSION_REQUEST: return {
                ...state,
                loading: true
            }
            case  UPDATE_PERMISSION_SUCCESS: return {
                ...state,
                updatedPermission: payload,
                loading: false
            }
    
            case UPDATE_PERMISSION_FAIL: return {
                ...state,
                error: payload,
                loading: false
            }
             
             default: return state
         }
     }