export const ADD_HOST_REQUEST="ADD_HOST_REQUEST"
export const ADD_HOST_SUCCESS="ADD_HOST_SUCCESS"
export const ADD_HOST_FAIL="ADD_HOST_FAIL"

export const GET_ALL_HOST_REQUEST="GET_ALL_HOST_REQUEST"
export const GET_ALL_HOST_SUCCESS="GET_ALL_HOST_SUCCESS"
export const GET_ALL_HOST_FAIL="GET_ALL_HOST_FAIL"

export const CHANGE_USER_STATUS_REQUEST = "CHANGE_USER_STATUS_REQUEST"
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS"
export const CHANGE_USER_STATUS_FAIL = "CHANGE_USER_STATUS_FAIL"