export const ADD_SUPERVISER_REQUEST="ADD_SUPERVISER_REQUEST"
export const ADD_SUPERVISER_SUCCESS="ADD_SUPERVISER_SUCCESS"
export const ADD_SUPERVISER_FAIL="ADD_SUPERVISER_FAIL"

export const CHANGE_SUPERVISOR_STATUS_REQUEST="CHANGE_SUPERVISOR_STATUS_REQUEST"
export const CHANGE_SUPERVISOR_STATUS_SUCCESS="CHANGE_SUPERVISOR_STATUS_SUCCESS"
export const CHANGE_SUPERVISOR_STATUS_FAIL="CHANGE_SUPERVISOR_STATUS_FAIL"

export const GET_SUPERVISER_REQUEST="GET_SUPERVISER_REQUEST"
export const GET_SUPERVISER_SUCCESS="GET_SUPERVISER_SUCCESS"
export const GET_SUPERVISER_FAIL="GET_SUPERVISER_FAIL"
export const OPEN_EDIT_SUPERVISOR = 'OPEN_EDIT_SUPERVISOR';
