import React, { Suspense, lazy, useState, createContext } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
// import { Router } from '@reach/router'
import Loader from "./modules/Components/Loader";
const Permission = lazy(() => import('./modules/Pages/Permission'));
// const Supervisor = lazy(() => import('./modules/Pages/Supervisor'));
const Banner = lazy(() => import('./modules/Pages/Banner'));
const Brand = lazy(() => import('./modules/Pages/Brand'));
const Host = lazy(() => import('./modules/Pages/Host'));
const Login = lazy(() => import('./modules/Pages/login'));
const Main = lazy(() => import('./modules/Pages/Main'));
const Dashboard = lazy(() => import('./modules/Pages/Dashboard'));
const LoginOnly = lazy(() => import('./modules/Components/LoginOnly'));
const PageNotFound = lazy(() => import('./modules/Pages/PageNotFound'));
const ChangePassword = lazy(() => import('./modules/Pages/changePassword'));
export const AdminContext = createContext()

function App() {
  const [errormsg, seterrormsg] = useState(false)
  const [page, setpage] = useState()
  const handleError = (error) => {
    if (error) {
      seterrormsg(true)
      setTimeout(() => {
        seterrormsg(false)
      }, 4000)

    }
  };
  return (
    <BrowserRouter>
    <Suspense fallback={<Loader />}>
        <AdminContext.Provider
          value={{
            errormsg: errormsg,
            seterrormsg: seterrormsg,
            handleError: handleError,
            page: page,
            setpage: setpage,
          }}
        >
        <Routes >
          <Route path="/*" element={<Main>
            <Routes>
              <Route exact path="dashboard" element={<LoginOnly element={<Dashboard />} />} />
                    <Route
                      path="banners"
                      element={<LoginOnly element={<Banner />} />}
                    />
              <Route path="brand" element={<LoginOnly element={<Brand />} />} />
              <Route path="host" element={<LoginOnly element={<Host />} />} />
              <Route path="permission" element={<LoginOnly element={<Permission />} />} />
              <Route path="changePassword" element={<LoginOnly element={<ChangePassword />} />} />
            </Routes>
          </Main>} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="*" element={<PageNotFound/>}/> */}
          {/* </Route> */}
        </Routes>
      </AdminContext.Provider>
    </Suspense>
    {/* </div> */}
    {/* <CssBaseline />
    </ThemeProvider> */}
  </BrowserRouter>
  );
}

export default App;
