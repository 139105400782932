import {
    createStore,
    combineReducers,
    applyMiddleware
} from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { adminReducer } from "./reducer/adminReducer"
import { hostReducer } from "./reducer/hostReducer"
import { bannerReducer } from "./reducer/bannerReducer"
import { brandReducer } from "./reducer/brandReducer"
import { permissionReducer } from "./reducer/permissionReducer"
import {supervisorReducer} from "./reducer/supervisorReducer"

const localStorageLogin = localStorage.getItem("adminLogin") 
? JSON.parse(localStorage.getItem("adminLogin"))
: null

const intialValue = {
    admin: {
        admin: localStorageLogin,
    }
}

const rootReducer = combineReducers({
    admin:adminReducer,
    host:hostReducer,
    banner:bannerReducer,
    permission:permissionReducer,
    supervisor:supervisorReducer,
    brand: brandReducer
})




const store = createStore(
    rootReducer,
    intialValue,
    composeWithDevTools(applyMiddleware(thunk))
)

export default store