export const ADD_BRAND_REQUEST = "ADD_BRAND_REQUEST"
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS"
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL"

export const GET_BRAND_REQUEST = "GET_BRAND_REQUEST"
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS"
export const GET_BRAND_FAIL = "GET_BRAND_FAIL"

export const GET_BANNER_TYPES_REQUEST = "GET_BANNER_TYPES_REQUEST"
export const GET_BANNER_TYPES_SUCCESS = "GET_BANNER_TYPES_SUCCESS"
export const GET_BANNER_TYPES_FAIL = "GET_BANNER_TYPES_FAIL"

export const CHANGE_BRAND_STATUS_REQUEST = 'CHANGE_BRAND_STATUS_REQUEST';
export const CHANGE_BRAND_STATUS_SUCCESS = 'CHANGE_BRAND_STATUS_SUCCESS';
export const CHANGE_BRAND_STATUS_FAIL = 'CHANGE_BRAND_STATUS_FAIL';

export const OPEN_EDIT_BRAND = 'OPEN_EDIT_BRAND';
export const EDIT_BRAND_REQUEST = 'ADD_BRAND_REQUEST';
export const EDIT_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS';
export const EDIT_BRAND_FAIL = 'ADD_BRAND_FAIL';
