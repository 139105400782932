import {
    ADD_BRAND_REQUEST,
    ADD_BRAND_SUCCESS,
    ADD_BRAND_FAIL,

    GET_BANNER_TYPES_REQUEST,
    GET_BANNER_TYPES_SUCCESS,
    GET_BANNER_TYPES_FAIL,

    GET_BRAND_REQUEST,
    GET_BRAND_SUCCESS,
    GET_BRAND_FAIL,

    CHANGE_BRAND_STATUS_REQUEST,
    CHANGE_BRAND_STATUS_SUCCESS,
  CHANGE_BRAND_STATUS_FAIL,
  OPEN_EDIT_BRAND,
  EDIT_BRAND_REQUEST,
  EDIT_BRAND_FAIL,
  EDIT_BRAND_SUCCESS,
} from './../constant/brandConstants';

export const brandReducer = (state = { brands: [] }, { type, payload }) => {
    switch (type) {

        case ADD_BRAND_REQUEST: return {
            ...state,
            loading: true
        }
        case ADD_BRAND_SUCCESS: return {
            ...state,
            addedBrand: payload,
            addBrand: true,
            loading: false
        }

        case ADD_BRAND_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }

        case GET_BRAND_REQUEST: return {
            ...state,
            loading: true
        }
        case GET_BRAND_SUCCESS: return {
            ...state,
            brands: payload,
            loading: false
        }

        case GET_BRAND_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }

        case GET_BANNER_TYPES_REQUEST: return {
            ...state,
            loading: true
        }
        case GET_BANNER_TYPES_SUCCESS: return {
            ...state,
            bannerTypes: payload,
            loading: false
        }

        case GET_BANNER_TYPES_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }


        case CHANGE_BRAND_STATUS_REQUEST: return {
            ...state,
            loading: true
        }
        case CHANGE_BRAND_STATUS_SUCCESS: return {
            ...state,
            changedStatus: payload,
            loading: false
        }

        case CHANGE_BRAND_STATUS_FAIL: return {
            ...state,
            error: payload,
        loading: false,
      };

    case OPEN_EDIT_BRAND:
      return {
        ...state,
        brandDetails: payload,
        loading: true,
      };

    case EDIT_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_BRAND_SUCCESS:
      return {
        ...state,
        editedBrand: payload,
        editBrand: true,
        loading: false,
        brandDetails: {},
      };

    case EDIT_BRAND_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
    }
};
