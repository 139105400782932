import {
  ADD_BANNER_REQUEST,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAIL,
  GET_BANNER_TYPES_REQUEST,
  GET_BANNER_TYPES_SUCCESS,
  GET_BANNER_TYPES_FAIL,
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAIL,
  CHANGE_BANNER_STATUS_REQUEST,
  CHANGE_BANNER_STATUS_SUCCESS,
  CHANGE_BANNER_STATUS_FAIL,
  OPEN_EDIT_BANNER,
  EDIT_BANNER_REQUEST,
  EDIT_BANNER_SUCCESS,
  EDIT_BANNER_FAIL,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL
} from './../constant/bannerConstants';

export const bannerReducer = (state = { banners: [] }, { type, payload }) => {
  console.log(type + ':' + payload, 'type');
  switch (type) {
    case ADD_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        addedBanner: payload,
        addBanner: true,
        loading: false,
      };

    case ADD_BANNER_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case EDIT_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_BANNER_SUCCESS:
      return {
        ...state,
        editedBanner: payload,
        editBanner: true,
        loading: false,
        bannerDetail: {},
      };

    case EDIT_BANNER_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case GET_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BANNER_SUCCESS:
      return {
        ...state,
        banners: payload,
        loading: false,
      };

    case GET_BANNER_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case GET_BANNER_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BANNER_TYPES_SUCCESS:
      return {
        ...state,
        bannerTypes: payload,
        loading: false,
      };

    case GET_BANNER_TYPES_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case CHANGE_BANNER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_BANNER_STATUS_SUCCESS:
      return {
        ...state,
        changedStatus: payload,
        loading: false,
      };

    case CHANGE_BANNER_STATUS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        deletedStatus: payload,
        loading: false,
      };

    case DELETE_BANNER_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case OPEN_EDIT_BANNER:
      return {
        ...state,
        bannerDetail: payload,
        loading: true,
      };
    default:
      return state;
  }
};
