export const ADD_PERMISSION_REQUEST="ADD_PERMISSION_REQUEST"
export const ADD_PERMISSION_SUCCESS="ADD_PERMISSION_SUCCESS"
export const ADD_PERMISSION_FAIL="ADD_PERMISSION_FAIL"

export const GET_PERMISSION_REQUEST="GET_PERMISSION_REQUEST"
export const GET_PERMISSION_SUCCESS="GET_PERMISSION_SUCCESS"
export const GET_PERMISSION_FAIL="GET_PERMISSION_FAIL"

export const UPDATE_PERMISSION_REQUEST="UPDATE_PERMISSION_REQUEST"
export const UPDATE_PERMISSION_SUCCESS="UPDATE_PERMISSION_SUCCESS"
export const UPDATE_PERMISSION_FAIL="UPDATE_PERMISSION_FAIL"