import {
ADMIN_LOGIN_REQUEST,
ADMIN_LOGIN_SUCCESS,
ADMIN_LOGIN_FAIL,
ADMIN_LOGOUT
 
 } from "./../constant/adminConstants"
 
 export const adminReducer = (state = { admin: [] }, { type, payload }) => {
     switch (type) {
 
         case ADMIN_LOGIN_REQUEST: return {
             ...state,
             loading: true
         }
         case  ADMIN_LOGIN_SUCCESS: return {
             ...state,
             admin: payload,
             adminLogin:true,
             loading: false
         }
 
         case ADMIN_LOGIN_FAIL: return {
             ...state,
             error: payload,
             loading: false
         }

         case ADMIN_LOGOUT: return {
           
        }
         
         default: return state
     }
 }