import {
    ADD_HOST_REQUEST,
    ADD_HOST_SUCCESS,
    ADD_HOST_FAIL,
    GET_ALL_HOST_REQUEST,
    GET_ALL_HOST_SUCCESS,
    GET_ALL_HOST_FAIL,
    CHANGE_USER_STATUS_REQUEST,
    CHANGE_USER_STATUS_SUCCESS,
    CHANGE_USER_STATUS_FAIL
} from "./../constant/hostConstants"

export const hostReducer = (state = { allHosts: {}, hosts: {} }, { type, payload }) => {
    switch (type) {

        case ADD_HOST_REQUEST: return {
            ...state,
            loading: true
        }
        case ADD_HOST_SUCCESS: return {
            ...state,
            addedHost: payload,
            addHost: true,
            error: '',
            loading: false
        }

        case ADD_HOST_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }
        case GET_ALL_HOST_REQUEST: return {
            ...state,
            loading: true
        }
        case GET_ALL_HOST_SUCCESS: return {
            ...state,
            allHosts: payload,
            loading: false
        }

        case GET_ALL_HOST_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }
        case CHANGE_USER_STATUS_REQUEST: return {
            ...state,
            loading: true
        }
        case CHANGE_USER_STATUS_SUCCESS: return {
            ...state,
            changedStatus: payload,
            loading: false
        }

        case CHANGE_USER_STATUS_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }


        default: return state
    }
}